#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

body {
  font-family: 'GenosGFG Regular';
  background-color:#f9fbfd;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px;
}

button{
  border-radius: 5px !important;
}

h2{
  font-size: 16px;
}
@font-face {
  font-family: 'GenosGFG Regular';
  src: local('GenosGFG Regular'),
       url('./Assets/Fonts/GenosGFG-Regular.woff') format('woff');
  font-style: normal;
  font-weight: normal;
}
